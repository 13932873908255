import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { getCardUser } from 'src/utils/auth';

const CardGuard: FC = ({ children }) => {
  const cardUser = getCardUser();
  if (!cardUser) {
    return <Redirect to="/" />;
  }
  return <>{children}</>;
};

export default CardGuard;
