import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Colmena Seguros"
      src="/static/colmena_white_logo.svg"
      style={{ maxHeight: 52, maxWidth: 200, marginLeft: 15 }}
      {...props}
    />
  );
}

export default Logo;
