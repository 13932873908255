import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { getCardUser } from 'src/utils/auth';

const GuestCardGuard: FC = ({ children }) => {
  const cardUser = getCardUser();
  if (cardUser) {
    return <Redirect to="/carnet" />;
  }
  return <>{children}</>;
};

export default GuestCardGuard;
