/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CardGuard from './components/CardGuard';
import GuestCardGuard from './components/GuestCardGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import rolesGuard from './components/RolesGuard';
import DashboardLayout from './layouts/DashboardLayout';

type RoutesType = {
  exact?: boolean;
  path?: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: RoutesType[];
};

const routesConfig: RoutesType[] = [
  {
    exact: true,
    path: '/',
    guard: GuestCardGuard,
    component: lazy(() => import('./views/cards/SolidariaView'))
  },
  {
    exact: true,
    guard: CardGuard,
    path: '/carnet',
    component: lazy(() => import('./views/cards/SolidariaView/Card'))
  },
  {
    exact: true,
    guard: CardGuard,
    path: '/logout',
    component: lazy(() => import('./views/cards/SolidariaView/Logout'))
  },
  {
    exact: true,
    guard: CardGuard,
    path: '/avisos',
    component: lazy(() => import('./views/cards/SolidariaView/Notices'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: lazy(() => import('./views/terms/PrivacyPolicyView'))
  },
  {
    exact: true,
    path: '/terms-of-service',
    component: lazy(() => import('./views/terms/ToSView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    path: '/dashboard',
    guard: rolesGuard(['admin', 'employee']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/dashboard',
        component: lazy(() => import('./views/pages/DashboardView'))
      }
    ]
  },
  {
    path: '/policies',
    guard: rolesGuard(['admin', 'employee']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/policies',
        component: lazy(() => import('./views/pages/PoliciesView/PoliciesList'))
      },
      {
        exact: true,
        path: '/policies/:id/:tab',
        component: lazy(
          () => import('./views/pages/PoliciesView/PoliciesDetail')
        )
      }
    ]
  },
  {
    path: '/users',
    guard: rolesGuard(['admin']),
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('./views/pages/UsersView/UsersList'))
      }
    ]
  },
  {
    path: '*',
    guard: GuestGuard,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes: RoutesType[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props: any) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
