import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Colmena Seguros"
      src="/static/colmena_logo.svg"
      style={{ maxHeight: 150, maxWidth: '95%' }}
      {...props}
    />
  );
}

export default Logo;
