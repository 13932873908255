import client from 'src/utils/client';
import { AccountState } from '../store/account/types';

type CardLoginType = (username: string) => Promise<Affiliate[]>;
type LoginType = (username: string, password: string) => Promise<LoginResponse>;
type UpdateUserType = (update: Partial<AccountState['user']>) => Promise<User>;
type UpdateStringType = (update: string) => Promise<any>;
type UpdateFormDataType = (update: FormData) => Promise<User>;

export const cardLogin: CardLoginType = (username) =>
  client
    .post('auth/card-login', {
      json: {
        username
      }
    })
    .json();

export const loginUser: LoginType = (username, password) =>
  client
    .post('auth/login', {
      json: {
        username,
        password
      }
    })
    .json();

export const updateProfileRequest: UpdateUserType = (update) =>
  client
    .patch('auth/me', {
      json: {
        update
      }
    })
    .json();

export const updatePasswordRequest: UpdateStringType = (update) =>
  client
    .post('auth/me/set-password', {
      json: {
        update
      }
    })
    .json();

export const updatePhotoRequest: UpdateFormDataType = (update) =>
  client
    .post('auth/me/set-photo', {
      body: update
    })
    .json();

type GetUsersType = (
  searchParams?: QueryParams
) => Promise<PaginationResponse<User>>;
export const getUsers: GetUsersType = (searchParams) =>
  client.get(`auth/users`, { searchParams }).json();

type UploadUsersExcelType = (users: FormData) => Promise<void>;
export const uploadUsersExcel: UploadUsersExcelType = (users) =>
  client.post(`auth/upload-users`, { body: users }).json();
